<template>
  <div>
    <form-section :title="$t('courierSet.parcelContent.title')">
      <v-row class="border-bottom">
        <v-col cols="4">
          <div class="label">
            <span> {{ $t(`courierSet.packageContent`) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col>
                <text-field
                  v-model="getParcel.content"
                  :title="$t('courierSet.parcelContent.defaultParcelContent')"
                  style="max-width: 330px"
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, idx) in getItems('CONTENT')"
                :key="generateUid() + '_' + item.sourceField"
                class="content-wrapper"
                cols="12"
              >
                <v-select
                  v-model="item.sourceField"
                  :items="getFieldsToMap('CONTENT', item.sourceField)"
                  :label="$t('courierSet.parcelContent.title')"
                  item-value="value"
                  item-text="text"
                  append-icon="mdi-chevron-down"
                  dense
                  hide-details
                  outlined
                  cache-items
                  persistent-placeholder
                  clearable
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="onChange(item)"
                />
                <v-btn
                  v-if="!!idx"
                  icon
                  tile
                  color="danger"
                  min-height="40"
                  style="border-radius: 4px"
                  @click="onRemoveFieldMapping(item)"
                >
                  <v-icon> mdi-delete-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="add-button-wrapper"
              >
                <v-btn
                  :disabled="isAddButtonDisabled('CONTENT')"
                  color="primary lighten-1"
                  text
                  block
                  @click="onAddFieldMapping('CONTENT')"
                >
                  + {{ $t('actions.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="border-bottom">
        <v-col cols="4">
          <div class="label">
            <span> {{ $t(`courierSet.parcelContent.referenceNumber`) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col class="py-2">
                <text-field
                  v-model="getDpdDefaultValues.referenceLine1"
                  :title="$t('courierSet.parcelContent.line1')"
                  style="max-width: 330px"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-2">
                <text-field
                  v-model="getDpdDefaultValues.referenceLine2"
                  :title="$t('courierSet.parcelContent.line2')"
                  style="max-width: 330px"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-2">
                <text-field
                  v-model="getDpdDefaultValues.referenceLine3"
                  :title="$t('courierSet.parcelContent.line3')"
                  style="max-width: 330px"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="border-bottom">
        <v-col cols="4">
          <div class="label">
            <span> {{ $t(`courierSet.parcelContent.deliveryRemarks`) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col>
                <text-field
                  v-model="getParcel.customerData1"
                  :title="$t('courierSet.parcelContent.defaultRemarksDescription')"
                  style="max-width: 330px"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="item in getItems('REMARKS')"
                :key="generateUid() + '_' + item.sourceField"
                class="content-wrapper"
                cols="12"
              >
                <v-select
                  v-model="item.sourceField"
                  :items="getFieldsToMap('REMARKS', item.sourceField)"
                  :label="$t('courierSet.parcelContent.deliveryRemarks')"
                  item-value="value"
                  item-text="text"
                  append-icon="mdi-chevron-down"
                  dense
                  hide-details
                  outlined
                  cache-items
                  persistent-placeholder
                  clearable
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="onChange(item)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row class="border-bottom">
        <v-col cols="4">
          <div class="label">
            <span> {{ $t(`courierSet.parcelContent.other`) }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col cols="12">
                <text-field
                  v-model="getParcel.customerData2"
                  :title="$t('courierSet.parcelContent.customerData1')"
                  style="max-width: 330px"
                />
              </v-col>
              <v-col cols="12">
                <text-field
                  v-model="getParcel.customerData3"
                  :title="$t('courierSet.parcelContent.customerData2')"
                  style="max-width: 330px"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FIELDS } from '@/constants/FieldsToMap.js';
import additionalFieldsMixin from '@/mixins/additionalFieldsMixin';

const ADDITIONAL_FIELDS_CONFIGURATION = [
  {
    name: 'packageContent',
    code: 'CONTENT',
    displayAddFieldButton: true,
    label: 'courierSet.parcelContent.title',
  },
  {
    name: 'parcelContent.deliveryRemarks',
    code: 'REMARKS',
    displayAddFieldButton: false,
    label: 'courierSet.parcelContent.deliveryRemarks',
  },
];

const COURIER_FIELDS = [
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'CONTENT',
  },
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'REMARKS',
  },
];

export default {
  name: 'ContentAndComments',
  mixins: [additionalFieldsMixin],
  data: () => ({
    FIELDS,
    ADDITIONAL_FIELDS_CONFIGURATION,
    COURIER_FIELDS,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getParcel() {
      return this.getConfigurationSet().dpdDefaultValues.parcel;
    },
    getDpdDefaultValues() {
      return this.getConfigurationSet().dpdDefaultValues;
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  font-size: 1rem;
  margin-top: 12px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  margin-bottom: 12px;

  .v-select {
    height: 48px !important;
    max-width: 330px;
  }
}

.add-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  max-width: 355px;
}

.border-bottom {
  border-bottom: 1px solid $text-lighten2;
  margin: 0px 6px 12px 6px;
  padding-bottom: 12px;
}
</style>
