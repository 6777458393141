<template>
  <div>
    <form-section :title="$t('courierSet.labelSettingsName')">
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.type"
            :title="$t('courierSet.labelSettings.labelPrintTypeDpd')"
            rules="required"
            :filed-items="types"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.format"
            :title="$t('courierSet.labelSettings.format')"
            rules="required"
            :filed-items="formats"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSettings.fileType"
            :title="$t('courierSet.labelSettings.labelPrintFormat')"
            rules="required"
            :filed-items="fileTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { formats } from '@/constants/Speditors/DPD/Formats';
import { fileTypes } from '@/constants/Speditors/DPD/FileTypes';
import { types } from '@/constants/Speditors/DPD/Types';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      formats,
      fileTypes,
      types,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getLabelSettings() {
      return this.getConfigurationSet().labelSettings;
    },
  },
};
</script>

<style scoped></style>
